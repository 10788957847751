/* @font-face {
  font-family: "Barthowheel";
  src: url("../../fonts/barthowheel-regular.ttf") format("truetype");
} */

*:focus {
  outline: 0;
  outline: none;
}

.container {
  background-color: #000;
  display: block;
  position: relative;
  height: 100vh;
  /* left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute; */
}

.topTextContainer {

}

.video-container {
	position: absolute;
  z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background: black url('https://traversymedia.com/downloads/cover.jpg') no-repeat center
		center/cover;
}

.video-container video {
	min-width: 100%;
	min-height: 100%;
  position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	object-fit: cover;
}

.content {
	z-index: 2;
  justify-content: space-between;
  width: 100%;
  display: flex;
  align-items: flex-start;
  position: absolute;
  /* padding: 36px; */
}

.topTextContainer {
  margin: 36px
}
.video-container:after {
	content: '';
	z-index: 1;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.5);
	position: absolute;
}

.homescreen-container {
  width: 100%;
  height: 100%;
}

.homescreen-inner-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: fit-content;
  margin: 0 auto;
  top: 50%;
  position: absolute;
  align-items: center;
  left: 50%;
  transform: translate(-50%, -50%);
}

.subText {
  color: #000;
  text-align: center;
  font-size: 28px;
  margin-top: 10px;
}

.homepage-bg {
  width: auto;
  height: 56px;
}
.home {
  width: 100%;
  height: 100vh;
}

.tiles-container {
  width: 100%;
  padding-bottom: 100px;
  padding-top: 100vh;
  /* min-height: 100vh; */
}

.tiles-header {
  height: 100px;
  width: 100%;
  background-color: #000;
}

.ctaContainer {
  display: flex;
  color: #000;
  font-size: 38px;
  margin: 36px;
  -webkit-text-stroke: 1px #fff;
  /* grid-template-columns: 1fr 1fr; */
  /* grid-gap: 32px; */
  /* outline: none; */
  /* width: fit-content; */
}

/* .ctaContainer:focus {
  outline: none;
} */

.ctaLink {
  text-decoration: none;
  outline: none;
  margin-left: 44px;
  -webkit-text-stroke: 1px #fff;

}

.ctaLink:focus {
  outline: none;
}

.scroll-down {
  position:  absolute;
  bottom:64px;
  transform: translate(-50%, 0);
  left: 50%;
    display: flex;
    justify-content: center;
    animation: jumpInfinite 1.5s infinite;
}

.scroll-down-icon {
  width: 78px;
}

@keyframes jumpInfinite {
  0% {
    bottom: 64px;
  }
  50% {
    bottom: 20px;
  }
  100% {
    bottom: 64px;
  }
}

.tile {
  /* width: calc(100% / 3); */
  /* height: 250px; */
  /* border: 1px solid #fff; */
  cursor: pointer;
  position: relative;
}

.tiles-grid-wrapper {
  display: grid;
  /* grid-auto-rows: 446px; */
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.tile-hover-text {
  color: #fff;
  transition: 0.3s;
  opacity: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  /* line-height: 400px; */
  font-size: 56px;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tile-hover-text:hover {
  opacity: 1;
  transition: 0.3s;
}

.tile-img {
  height: 100%;
  width: 100%;
}

.tile-bg {
  width: auto;
  height: 100%;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
}

.overlay {
  width: 100%;
  height: 100vh;
  text-align: center;
  transition: 0.3s;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overscroll-behavior: contain;
}

.close-btn {
  cursor: pointer;
  position: absolute;
  right: 52px;
  top: 52px;
  font-size: 42px;
  transform: scaleX(1.35);
  color: #fff;
}

.close-btn:hover {
  color: #000;
}
@media (min-width: 600px) and (max-width: 1024px) {
  .tiles-grid-wrapper {
    grid-auto-rows: auto;
  }
  .tile-hover-text {
    font-size: 18px;
  }
  .homepage-bg {
    width: 48%;
    height: auto;
  }
  .subText {
    font-size: 20px;
  }
  .ctaContainer {
    margin-top: 36px;
    font-size: 26px;
  }
}

@media (max-width: 600px) {
  .homepage-bg {
    width: 100%;
    height: auto;
  }
  .subText {
    font-size: 20px;
  }
  .tiles-grid-wrapper {
    grid-auto-rows: auto;
    grid-template-columns: 1fr;
    max-width: 414px;
    margin: 0 auto;
  }
  .close-btn {
    top: 8px;
    left: 18px;
    right: unset;
  }
  .tile-hover-text {
    display: none;
  }
  .tile-bg {
    width: 100%;
    height: auto;
  }
  .overlay {
    display: flex;
  }
}
