.about-page-container {
  background-color: #e2e2e2;
  font-size: 38px;
  color: #60656a;
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
  min-height: 100vh;
  margin: 0 auto;
  text-align: center;
  flex-direction: row;
  /* padding-top: 8%; */
}

.footer {
  /* position: absolute; */
  /* bottom: 0; */
  font-size: 22px;
  padding: 12px 0px;
  font-weight: bold;
  /* left: 50%; */
  /* transform: translate(-50%, 0); */
}

.email {
  font-size: 18px;
  font-weight: normal;
  padding: 4px 0px;
}

.copyright {
  font-size: 16px;
  font-weight: normal;
  padding-top: 4px;
}

.logo {
  height: 40px;
  margin-top: 60px;
}

.text {
  width: 75%;
  margin: 0 auto;
  margin-top: 10px;
}

.office-pic {
  max-width: 76%;
  height: auto;
}

.left {
  position: relative;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.right {
  flex: 1;
  display: block;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mobile {
  display: none;
}

.desktop {
  display: block;
}

@media (min-width: 600px) and (max-width: 1200px) {
  .about-page-container {
    font-size: 24px;
    padding: 0px 16px;
  }
}

@media (max-width: 600px) {
  .about-page-container {
    font-size: 20px;
    padding: 0px 16px;
  }
  .text {
    width: 100%;
  }
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
  .left {
    width: 100%;
    align-items: center;
  }
  .logo {
    margin-top: 48px;
    object-fit: contain;
    width: 100%;
  }

  .office-pic {
    width: auto;
    max-width: 93%;
  }

  .right {
    display: none;
  }
  .text {
    margin-bottom: 0;
    width: 95%;
  }
}
